<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="danger"
  >
    Are you sure you want to delete this {{ $options.entitySingleLowerCase }} "{{ item.name }}"?
    <template #header>
      <h3 class="modal-title">Delete {{ $options.entitySingle }}</h3>
      <CButtonClose @click="hideForm" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onDeleteItem" color="success">Delete</CButton>
      <CButton @click="hideForm" color="danger">Discard</CButton>
    </template>
  </CModal>
</template>

<script>
import startCase from 'lodash/startCase'
import { mapActions, mapState } from 'vuex'
import { entity, entitySingle } from '../entity'

export default {
  name: 'Delete',
  computed: {
    ...mapState({
      item: state => state[entity].delete.data
    })
  },
  entitySingle: startCase(entitySingle),
  entitySingleLowerCase: entitySingle,
  methods: {
    ...mapActions({
      deleteItem: `${entity}/deleteItem`
    }),
    onDeleteItem () {
      const { item } = this
      this.deleteItem({ id: item.id, data: item })
    },
    hideForm () {
      this.$store.commit(`${entity}/DELETE_DATA`, null)
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
