export default [
  {
    fieldKey: 'name',
    type: 'input',
    label: 'Name',
    default: ''
  },
  {
    fieldKey: 'short_name',
    type: 'input',
    label: 'Short Name',
    default: ''
  }
]
